@import url('https://fonts.googleapis.com/css2?family=Gloock&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

.new {
  font-family: 'Gloock', serif;
  font-weight: 400;
}

.new1 {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.about {
  color: #bdb2ff;
}

.gradient-text {
  background: -webkit-linear-gradient(left, red, orange);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.custom-dot-list-style .react-multi-carousel-dot button {
  border: 1px solid white;
  background: transparent;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  padding: 5px;
  margin: 0 3px;
  transition: transform 0.3s ease-in-out;
}

.custom-dot-list-style .react-multi-carousel-dot--active button {
  transform: scale(1);
  background: white;
}

.h-half {
  height: 110vh;
}

.carousel {
  position: relative;
  width: 100%;
  margin: 0 0;
  overflow: hidden;
}

.carousel__track-container {
  width: 100%;
  overflow: hidden;
}

.carousel__track {
  display: flex;
  animation: scroll 10s linear infinite;
}

.carousel__slide {
  width: calc(100% / 8);
  flex: 0 0 auto;
}

@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}
